import styled from 'styled-components';
import { Body } from './Text';

const ErrorText = styled(Body)`
  font-size: 0.8rem;
  text-align: left;
  margin: 0;
`;

const GraphQLErrorText = ({ error }) => {
  return error.graphQLErrors.map((e) => {
    return e.extensions &&
      e.extensions.exception &&
      e.extensions.exception.response &&
      typeof e.extensions.exception.response.message === 'string' ? (
      <ErrorText
        className="text-red-400"
        key={e.extensions.exception.response.message}
      >
        {e.extensions.exception.response.message}
      </ErrorText>
    ) : e.extensions &&
      e.extensions.exception &&
      e.extensions.exception.response &&
      typeof e.extensions.exception.response.code === 'string' ? (
      <ErrorText
        className="text-red-400"
        key={e.extensions.exception.response.code}
      >
        {e.extensions.exception.response.code}
      </ErrorText>
    ) : e.extensions &&
      e.extensions.exception &&
      e.extensions.exception.response &&
      e.extensions.exception.response.message ? (
      e.extensions.exception.response.message.map((m) => (
        <ErrorText className="text-red-400" key={m}>
          {m}
        </ErrorText>
      ))
    ) : (
      <ErrorText className="text-red-400" key={e.message}>
        {e.message}
      </ErrorText>
    );
  });
};

export default GraphQLErrorText;
