import { useMutation } from '@apollo/react-hooks';
import { Field, FormikProvider, useFormik } from 'formik';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import styled from 'styled-components';
import { Box, CenterCol as Center, FlexGrow, Row } from '../../components/Flex';
import { BackgroundButton, EditableInput } from '../../components/Form';
import GraphQLErrorText from '../../components/GraphQLErrorText';
import GoogleButton from '../../components/common/button/oauthButtons/google';
import { Body, HeadingOne } from '../../components/Text';
import { COOKIE } from '../../constants';
import redirectUri from '../../hooks/redirectUri';
import { LOGIN } from '../../lib/graphql/mutations';
import { WHITE_LABEL_MARKET } from '../../lib/white-label';
import { COLORS } from '../../theme';
import * as Yup from 'yup';
import { analytics } from '../../lib/analytics';

const Container = styled(Row)`
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  height: 100vh;
`;

const Left = styled(Center)`
  width: 90%;
  min-width: 450px;
  max-width: 700px;
  background: linear-gradient(190deg, #0688f3, #1cc9cb);
  height: 100vh;
  @media only screen and (max-width: 850px) {
    display: none;
  }
`;

const Right = styled(Center)`
  width: 100%;
`;

const Title = styled(HeadingOne)`
  text-align: center;
`;

const Inputs = styled.form`
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
`;

const A = styled.a`
  color: ${COLORS.TEAL_BLUE};
  cursor: pointer;
  text-align: right;
  margin: 0;
  &:hover {
    text-decoration: underline;
  }
`;

const Text = styled(Body)`
  font-size: 1rem;
  text-align: right;
  margin: 0;
`;

const LoginSchema = Yup.object().shape({
  usernameOrEmail: Yup.string().required('Required'),
  password: Yup.string()
    .min(8, 'Must be longer then 8 characters')
    .required('Required'),
});

function Login() {
  const [cookies, setCookie] = useCookies([COOKIE.ACCESS_TOKEN]);

  const router = useRouter();
  const [passwordType, setPasswordType] = useState(true);

  const onIconClick = () => {
    setPasswordType(!passwordType);
  };

  const [login, { loading, error }] = useMutation(LOGIN);

  useEffect(() => {
    // make sure we are not routing if one is already in progress
    const redirectPath = redirectUri();
    if (cookies[COOKIE.ACCESS_TOKEN] && router.pathname === '/login') {
      router.push(redirectPath);
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      usernameOrEmail: '',
      password: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      login({
        variables: {
          usernameOrEmail: values.usernameOrEmail,
          password: values.password,
        },
      })
        .then((res) => {
          if (res.data.login) {
            analytics.track('User Logged In');
            setCookie(COOKIE.ACCESS_TOKEN, res.data.login.accessToken, {
              expires: new Date(2147483647000),
              path: '/',
            });
            setCookie(COOKIE.REFRESH_TOKEN, res.data.login.refreshToken, {
              expires: new Date(2147483647000),
              path: '/',
            });
            if (!res.data.login.hasVerifiedEmail) {
              return router.push('/register/verify-email');
            } else if (!res.data.login.discordConnection) {
              return router.push('/register/connect-discord');
            } else {
              router.push(redirectUri());
            }
          }
        })
        .catch((err) => {});
    },
  });

  return (
    <Container>
      <Head>
        <title>Login - {WHITE_LABEL_MARKET}</title>
      </Head>
      <Left>
        <img alt="" src="/images/bot-ui@2x.png" />
      </Left>
      <Right>
        <Title>Log In</Title>
        <Box height={10} />
        <Text>
          Don't have an account?&nbsp;
          <Link href="/register" legacyBehavior>
            <A>Sign Up</A>
          </Link>
        </Text>
        <Box height={20} />
        <Row>
          <FlexGrow />
          <GoogleButton />
          <FlexGrow />
        </Row>
        <Box height={40} />
        <FormikProvider value={formik}>
          <Inputs onSubmit={formik.handleSubmit}>
            <Field
              name="usernameOrEmail"
              placeholder="Username or Email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              component={EditableInput}
            />
            <Box height={20} />
            <Field
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Password"
              type={passwordType ? 'password' : 'text'}
              icon="/images/eye-close-up@2x.png"
              onIconClick={onIconClick}
              component={EditableInput}
            />
            <Box height={10} />
            {error ? <GraphQLErrorText error={error} /> : null}

            <Box height={10} />
            <Row>
              <Link href="/login/forgot" legacyBehavior>
                <A>Forgot Password</A>
              </Link>
              <FlexGrow />
              <BackgroundButton
                disabled={loading || !formik.isValid}
                type="submit"
              >
                Log In
              </BackgroundButton>
            </Row>
          </Inputs>
        </FormikProvider>
      </Right>
    </Container>
  );
}

export async function getStaticProps() {
  return {
    props: {
      defaultSpacing: false,
    },
  };
}

export default Login;
